'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

export const providerQueryClient = new QueryClient();

export default function ClientProviders({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <QueryClientProvider client={providerQueryClient}>
      {children}
    </QueryClientProvider>
  );
}
