"use client";

import { FlagsmithClientProvider } from "@openfeature/flagsmith-client-provider";
import { OpenFeature, OpenFeatureProvider } from "@openfeature/react-sdk";
import flagsmith from "flagsmith/isomorphic";
import React from "react";
import { IState } from 'flagsmith/types';

type Props = {
  state: IState<string,string>;
  children: React.ReactNode;
};

const OpenFeatureClientProvider = ({ state, children }: Props) => {
  const flagsmithClientProvider = new FlagsmithClientProvider({
    environmentID: state.environmentID,
    api: state.api,
    flagsmithInstance: flagsmith,
    state,
  });
  OpenFeature.setProvider(flagsmithClientProvider); // Attach the provider to OpenFeature

  return <OpenFeatureProvider>{children}</OpenFeatureProvider>;
};

export default OpenFeatureClientProvider;
